import { Exclude, Expose, plainToInstance, Transform, Type } from 'class-transformer';
import { get } from 'lodash';

// Constant
import { UPLOAD_SERVER } from '@Src/constant';

@Exclude()
export class ImageModel {
    @Expose()
    id: string;

    @Expose()
    name: string;

    @Expose()
    alt: string;

    @Expose()
    width: number;

    @Expose()
    height: number;

    @Expose()
    @Transform(({ obj }) => {
        return UPLOAD_SERVER + get(obj, 'url', '');
    })
    url: number;

    @Expose()
    @Transform(({ obj }) => {
        const formats = get(obj, 'formats', {});

        return plainToInstance(ImageFormatsModel, formats, {});
    })
    formats: ImageFormatsModel;
}

@Exclude()
export class ImageFormatsModel {
    @Expose()
    @Type(() => ImageFormatModel)
    small: ImageFormatModel;

    @Expose()
    @Type(() => ImageFormatModel)
    medium: ImageFormatModel;

    @Expose()
    @Type(() => ImageFormatModel)
    thumbnail: ImageFormatModel;
}

@Exclude()
export class ImageFormatModel {
    @Expose()
    width: number;

    @Expose()
    height: number;

    @Expose()
    @Transform(({ obj }) => {
        return UPLOAD_SERVER + get(obj, 'url', '');
    })
    url: number;
}
