import dynamic from 'next/dynamic';
import classNames from 'classnames';

const Drawer = dynamic(() => import('antd/lib/drawer'), {
    ssr: false,
});

// Components
import { SvgIcon } from '@Components/svg-definition';

interface IAudioFile {
    title: string;
    duration: string;
    url?: string;
    url2?: string;
    active: boolean;
}

interface IBookChaptersDrawerProps {
    play?: boolean;
    isShow: boolean;
    lists: IAudioFile[];
    onClickEpisode?: (audioFile: IAudioFile) => void;
    onCloseDrawer?: () => void;
}

export default function BookChaptersDrawer(props: IBookChaptersDrawerProps) {
    const { isShow, lists = [], onClickEpisode, onCloseDrawer } = props;

    const renderDrawerHeader = () => {
        return (
            <div className="flex flex-row justify-between items-center">
                <label>Các chương của sách</label>
                <div onClick={onCloseDrawer} className="h-full w-[20px]">
                    <SvgIcon name="close" />
                </div>
            </div>
        );
    };

    return (
        <div className="">
            <Drawer
                title={renderDrawerHeader()}
                placement="bottom"
                headerStyle={{ borderRadius: 4 }}
                closable={false}
                onClose={onCloseDrawer}
                open={isShow}
            >
                <ul className="w-full space-y-2 font-gilroy">
                    {lists.length
                        ? lists.map((audioFile, key) => {
                              return (
                                  <li
                                      key={key}
                                      onClick={() => onClickEpisode && onClickEpisode(audioFile)}
                                      className={classNames(
                                          'flex flex-row items-center cursor-pointer py-2 pr-4 rounded-xl mb-0.5',
                                          { 'bg-red-50': audioFile.active }
                                      )}
                                  >
                                      <div className="flex flex-none w-12 h-12">
                                          <div className="flex items-center justify-center text-gray-600 font-medium rounded w-12 h-12 text-2xl">
                                              {key + 1}
                                          </div>
                                      </div>
                                      <div className="flex flex-col justify-start flex-grow items-center pl-2">
                                          <div className="font-medium w-full text-left text-xs text-gray-600">
                                              {audioFile?.duration || ''}
                                          </div>
                                          <div className="cursor-pointer w-full font-medium text-base line-clamp-2 leading-[1.5rem]">
                                              {audioFile.title}
                                          </div>
                                          {/*<div className="relative w-full mt-1 space-y-1">*/}
                                          {/*    <div className="flex w-full h-0.5 bg-gray-400 rounded">*/}
                                          {/*        <div className="h-full w-1/2 bg-red-500 rounded"></div>*/}
                                          {/*    </div>*/}
                                          {/*    <label className="flex text-xs">Đã nghe 89%</label>*/}
                                          {/*</div>*/}
                                      </div>
                                  </li>
                              );
                          })
                        : null}
                </ul>
            </Drawer>
        </div>
    );
}
