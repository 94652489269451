import axios, { AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';

// Constant
import { API_SERVER } from '@Src/constant';

// Utils
// import {parseError} from '@Src/utils';

interface IServiceConfig {
    baseUrl?: string;
    url?: string;
    authorization?: boolean;
    headersConfig?: Record<string, any>;
}

interface IPayloadRequest {
    data?: Record<string, any>;
    url?: string;
}

class Service {
    http: any;

    baseUrl: string;

    url: string;

    authorization: boolean;

    headersConfig: Record<string, any>;

    private headers!: {
        Authorization?: string;
        'content-type'?: string;
    };

    constructor(config: IServiceConfig) {
        try {
            const { baseUrl = API_SERVER, url = '', authorization = true, headersConfig = {} } = config;

            this.baseUrl = baseUrl;
            this.url = url;
            this.headersConfig = headersConfig;
            this.authorization = typeof authorization !== 'undefined' ? authorization : true;
            this.headers = {};

            // Create axios instant
            this.http = axios.create({
                baseURL: baseUrl,
            });

            this.http.interceptors.request.use(
                function (config: AxiosRequestConfig) {
                    return config;
                },
                function (error: AxiosError) {
                    // Do something with request error
                    return Promise.reject(error);
                }
            );

            this.http.interceptors.response.use(
                (response: AxiosResponse) => {
                    return response;
                },
                (error: AxiosError) => {
                    const { response } = error;

                    if (response) {
                        switch (response.status) {
                            case 400:
                            case 404:
                            case 500:
                            case 503:
                                // const {message = ''} = parseError(error);
                                //
                                // if (this.isShowDefaultErrorMessage && message) {
                                //     store.dispatch(showNotify({
                                //         type: 'warning',
                                //         message: t(message)
                                //     }));
                                // }

                                return Promise.reject(error);
                            case 401:
                                // Logout
                                // store.dispatch(logout());
                                return;
                            // case 403:
                            //     // No permission
                            //     reduxStore.dispatch(updateAuthenticate({
                            //         status: AUTHENTICATE_ACCESS_DENIED
                            //     }));
                            //     return;
                            default:
                                return Promise.reject(error);
                        }
                    }

                    return Promise.reject(error);
                }
            );
        } catch (error) {
            throw new Error('Service constructor error');
        }
    }

    setAuthorization({ accessToken, tokenType }: { accessToken: string; tokenType: string }): void {
        let headersConfigBase = {
            'content-type': 'application/json',
            Authorization: `${tokenType} ${accessToken}`,
        };

        if (this.headersConfig) {
            headersConfigBase = {
                ...headersConfigBase,
                ...this.headersConfig,
            };
        }

        this.headers = headersConfigBase;
    }

    // checkAuthorization(): boolean {
    //     const reduxStore = getOrCreateStore();
    //     const { accessToken = '' } = reduxStore.getState();
    //
    //     if (accessToken) {
    //         const tokenType = 'Bearer';
    //
    //         this.setAuthorization({
    //             accessToken,
    //             tokenType,
    //         });
    //
    //         return true;
    //     } else {
    //         // Error authorization
    //         throw new Error('The api require token');
    //     }
    // }

    get(payload: IPayloadRequest = {}): Promise<string> {
        const { url = '', data = {} } = payload;

        // Check authenticate
        // this.authorization && this.checkAuthorization();

        const paramsConfig = {
            params: data,
            headers: this.headers !== undefined ? this.headers : {},
        };

        return this.http.get(this.url + (url || ''), paramsConfig);
    }

    post(payload: IPayloadRequest = {}): Promise<string> {
        const { data = {}, url = '' } = payload;

        // Check authenticate
        // this.authorization && this.checkAuthorization();

        return this.http({
            data: data,
            method: 'POST',
            url: this.url + (url !== undefined ? url : ''),
            headers: this.headers !== undefined ? this.headers : {},
        });
    }

    put(payload: IPayloadRequest = {}): Promise<string> {
        const { data = {}, url = '' } = payload;

        // Check authenticate
        // this.authorization && this.checkAuthorization();

        return this.http({
            data: data,
            method: 'PUT',
            url: this.url + (url !== undefined ? url : ''),
            headers: this.headers !== undefined ? this.headers : {},
        });
    }

    patch(payload: IPayloadRequest = {}): Promise<string> {
        const { data = {}, url = '' } = payload;

        // Check authenticate
        // this.authorization && this.checkAuthorization();

        return this.http({
            data: data,
            method: 'PATCH',
            url: this.url + (url || ''),
            headers: this.headers !== undefined ? this.headers : {},
        });
    }

    delete(payload: IPayloadRequest = {}): Promise<string> {
        const { data = {}, url = '' } = payload;

        // Check authenticate
        // this.authorization && this.checkAuthorization();

        return this.http({
            data: data,
            method: 'DELETE',
            url: this.url + (url !== undefined ? url : ''),
            headers: this.headers !== undefined ? this.headers : {},
        });
    }
}

export default Service;
