import { Exclude, Expose } from 'class-transformer';

// Constants
import { API_BACKEND_URL } from '@Src/constant';
export const PUBLISHER_SERVICE_DEFINED = {
    baseUrl: API_BACKEND_URL,
    publishers: () => 'publishers',
};

@Exclude()
export class PublisherModel {
    @Expose()
    id: string;

    @Expose()
    name: string;

    @Expose()
    slug: string;
}
