import { Exclude, Expose, plainToInstance, Transform } from 'class-transformer';
import { get } from 'lodash';

@Exclude()
export class ResponseMetaModel {
    @Expose()
    id: string;

    @Expose()
    @Transform(({ obj }) => {
        const pagination = get(obj, 'pagination', {});

        return plainToInstance(ResponsePaginationModel, pagination, {});
    })
    pagination: ResponsePaginationModel;
}

@Exclude()
export class ResponsePaginationModel {
    @Expose()
    page: number;

    @Expose()
    pageCount: number;

    @Expose()
    total: number;
}
