import { Exclude, Expose, plainToInstance, Transform } from 'class-transformer';
import { get } from 'lodash';

// Model
import { ImageModel } from '@Services/image';

@Exclude()
export class AccountModel {
    @Expose()
    id: string;

    @Expose()
    fullName: string;

    @Expose()
    email: string;

    @Expose()
    @Transform(({ obj }) => {
        const avatar = get(obj, 'avatar', {});

        return plainToInstance(ImageModel, avatar, {});
    })
    avatar: ImageModel;
}
