import Image from 'next/image';
import { CaretRightOutlined } from '@ant-design/icons';
import classnames from 'classnames';

// Components
import { SvgIcon } from '@Components/svg-definition';

// Assets
import PlayingIcon from '@Assets/images/icon-playing.gif';

// Types
interface IAudioFile {
    title: string;
    duration: string;
    url: string;
    url2: string;
    active: boolean;
}

interface IBookChaptersProps {
    play?: boolean;
    isLoading?: boolean;
    lists: IAudioFile[];
    onClickPlayAudio?: (audio: IAudioFile) => void;
}

export default function BookChapters({ play = false, isLoading = false, lists, onClickPlayAudio }: IBookChaptersProps) {
    const onClickPlay = (audio) => {
        if (typeof onClickPlayAudio !== 'undefined') {
            onClickPlayAudio(audio);
        }
    };

    return (
        <ul className="w-full">
            {lists.length
                ? lists.map((audio, key) => {
                      return (
                          <li
                              key={key}
                              className="relative group flex flex-row items-center cursor-pointer p-2 rounded mb-0.5"
                          >
                              <div
                                  className={classnames(
                                      'absolute opacity-0 group-hover:opacity-30 bg-white top-0 bottom-0 left-0 right-0 rounded z-10',
                                      {
                                          'opacity-30': audio.active,
                                      }
                                  )}
                              />
                              <div className="relative w-12 h-12">
                                  <div
                                      className={classnames(
                                          'absolute top-0 bottom-0 left-0 right-0 bg-black opacity-0 group-hover:opacity-60 z-10 rounded',
                                          {
                                              'opacity-60': audio.active,
                                          }
                                      )}
                                  />

                                  {audio.active && play ? (
                                      <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center z-10">
                                          {!isLoading ? (
                                              <span className="rounded w-6 h-6">
                                                  <Image
                                                      width={24}
                                                      height={24}
                                                      src={PlayingIcon}
                                                      alt={`Nghe tiếp ${audio.title}`}
                                                  />
                                              </span>
                                          ) : (
                                              <SvgIcon size={40} name="loading" />
                                          )}
                                      </div>
                                  ) : (
                                      <span
                                          onClick={() => onClickPlay(audio)}
                                          className={classnames(
                                              'absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center z-10 opacity-0 group-hover:opacity-100',
                                              {
                                                  '!opacity-100': audio.active,
                                              }
                                          )}
                                      >
                                          <CaretRightOutlined style={{ fontSize: 24 }} className="text-white" />
                                      </span>
                                  )}
                                  <div className="flex items-center justify-center group-hover:invisible rounded w-12 h-12 text-white text-2xl">
                                      {key + 1}
                                  </div>
                              </div>
                              <div className="flex flex-col space-y-1 pl-2">
                                  <label className="text-white cursor-pointer leading-[1.5rem]">{audio.title}</label>
                                  <label className="text-xs text-gray-300 cursor-pointer">{audio.duration}</label>
                              </div>
                          </li>
                      );
                  })
                : null}
        </ul>
    );
}
