import Link from 'next/link';
import classNames from 'classnames';
import { get } from 'lodash';
import { PlayCircleFilled } from '@ant-design/icons';

// Components
import { SvgIcon } from '@Components/svg-definition';
import Rate from '@Components/rate';

// Types
import { IBook } from '@Types/book';

interface IBookProps extends IBook {
    isShowAuthor?: boolean;
    isShowBackdrop?: boolean;
    /**
     * Percentage of spend time listening to success
     */
    percentSuccess?: number;
    onClick?: any;
}

export default function Book({
    id,
    name,
    slug,
    hours = 0,
    minutes = 0,
    rate,
    rateNumber,
    author,
    cover,
    isShowAuthor = true,
    isShowBackdrop = false,
    percentSuccess = 0,
}: IBookProps) {
    return (
        <div className="flex flex-col pb-6 max-w-[167px] lg:max-w-[200px] min-w-[167px]">
            <Link title={name} href={`/book/${slug}-p${id}.html`}>
                <div
                    className={classNames(
                        'relative flex justify-between bg-cover lg:min-w-[200px] lg:h-[280px] h-[240px] overflow-hidden shadow rounded-md bg-gray-200'
                    )}
                >
                    <img
                        width={200}
                        height={280}
                        className="rounded-md h-full"
                        title={cover?.alt || name}
                        alt={cover?.alt || name}
                        src={get(cover, 'formats.medium.url', cover?.url)}
                    />
                    {percentSuccess ? (
                        <div className="absolute left-0 right-0 bottom-0 w-full h-1 bg-gray-400 z-10">
                            <div style={{ width: `${percentSuccess}%` }} className="h-full bg-red-500"></div>
                        </div>
                    ) : null}

                    {isShowBackdrop ? (
                        <div className="flex absolute top-0 left-0 right-0 bottom-0 backdrop-brightness-95 backdrop-blur-sm items-center justify-center">
                            <PlayCircleFilled
                                style={{ fontSize: 60 }}
                                className="opacity-90 text-white hover:opacity-100"
                            />
                        </div>
                    ) : null}
                </div>
                <div className="flex flex-col space-y-1 pt-2">
                    <h3 className="!mb-0 line-clamp-2">{name}</h3>
                    {isShowAuthor ? <h4 className="!font-normal">{author?.fullName}</h4> : null}
                    <div className="flex flex-col lg:flex-row space-y-2 lg:space-y-0 justify-between">
                        <Rate rate={rate || 100} />
                        {rateNumber ? <label className="text-sm">({rateNumber} nhận xét)</label> : null}
                    </div>
                    {hours || minutes ? (
                        <div className="flex flex-row items-center space-x-1">
                            <SvgIcon fill="#943C00" name="clock" />
                            <label style={{ color: '#943C00' }} className="flex items-center text-sm pt-0.5">
                                {hours || 0} giờ {minutes} phút
                            </label>
                        </div>
                    ) : null}
                </div>
            </Link>
        </div>
    );
}
