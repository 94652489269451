import { Exclude, Expose, plainToInstance, Transform } from 'class-transformer';
import { get } from 'lodash';

// Constants
import { API_BACKEND_URL } from '@Src/constant';

// Model
import { ImageModel } from '@Services/image';
import { CountryModel } from '@Services/country';

export const AUTHOR_SERVICE_DEFINED = {
    baseUrl: API_BACKEND_URL,
    authors: () => 'authors',
};

@Exclude()
export class AuthorModel {
    @Expose()
    id: string;

    @Expose()
    fullName: string;

    @Expose()
    slug: string;

    @Expose()
    description: string;

    @Expose()
    @Transform(({ obj }) => {
        const country = get(obj, 'country', {});

        return plainToInstance(CountryModel, country, {});
    })
    country: CountryModel;

    @Expose()
    @Transform(({ obj }) => {
        const avatar = get(obj, 'avatar', {});

        return plainToInstance(ImageModel, avatar, {});
    })
    avatar: ImageModel;
}
