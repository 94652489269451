// Components
import { SvgIcon } from '@Components/svg-definition';

// Types
interface IRateProps {
    rate: number;
    size?: number;
    fill?: string;
}

export default function Rate({ rate = 100, size = 14, fill = '#ffbb52' }: IRateProps) {
    // Convert to 1 - 5
    const rateValue = (rate * 5) / 100;
    const rateResult: string[] = [];

    for (let i = 1; i <= 5; i++) {
        if (rateValue >= i) {
            rateResult.push('star-fill');
        } else {
            if (i - rateValue < 1) {
                rateResult.push('star-half');
            } else {
                rateResult.push('star');
            }
        }
    }

    return (
        <div className="flex flex-row space-x-2">
            {rateResult.map((starName, index) => {
                return <SvgIcon key={index} size={size} name={starName} fill={fill} />;
            })}
        </div>
    );
}
