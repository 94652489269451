import { Exclude, Expose, plainToInstance, Transform, Type } from 'class-transformer';
import { get } from 'lodash';

// Constants
import { API_BACKEND_URL, UPLOAD_SERVER } from '@Src/constant';

// Models
import { AuthorModel } from '@Services/author';
import { CategoryModel } from '@Services/category';
import { PublisherModel } from '@Services/publisher';
import { ImageModel } from '@Services/image';
import { AccountModel } from '@Services/account';

export const BOOK_SERVICE_DEFINED = {
    baseUrl: API_BACKEND_URL,
    books: () => 'books',
    bookById: (bookId: string) => `books/${bookId}`,
    search: () => `books/search`,
    review: (bookId: string) => `${BOOK_SERVICE_DEFINED.books()}/${bookId}/reviews`,
};

@Exclude()
export class BookModel {
    @Expose()
    id: string;

    @Expose()
    name: string;

    @Expose()
    slug: string;

    @Expose()
    description: string;

    @Expose()
    @Transform(({ obj }) => {
        const cover = get(obj, 'cover', {});

        return plainToInstance(ImageModel, cover, {});
    })
    cover: ImageModel;

    @Expose()
    @Transform(({ obj }) => {
        const author = get(obj, 'author', []);
        const authors = plainToInstance(AuthorModel, author, {});

        if (Array.isArray(authors) && authors.length) {
            return {
                id: authors[0].id,
                fullName: authors[0].fullName,
                slug: authors[0].slug,
            };
        } else {
            return {};
        }
    })
    author: AuthorModel;

    @Expose()
    @Transform(({ obj }) => {
        const categories = get(obj, 'categories', []);

        return plainToInstance(CategoryModel, categories, {});
    })
    categories: CategoryModel;

    @Expose()
    @Transform(({ obj }) => {
        const publisher = get(obj, 'publisher', {});

        return plainToInstance(PublisherModel, publisher, {});
    })
    publisher: PublisherModel;

    @Expose()
    @Transform(({ obj }) => {
        return get(obj, 'properties.hours', 0);
    })
    hours: number;

    @Expose()
    @Transform(({ obj }) => {
        return get(obj, 'properties.minutes', 0);
    })
    minutes: number;

    @Expose()
    @Transform(({ obj }) => {
        return get(obj, 'properties.releaseYear', '');
    })
    releaseYear: number;

    @Expose()
    @Transform(({ obj }) => {
        return get(obj, 'properties.pageNumber', 0);
    })
    pageNumber: number;

    @Expose()
    @Transform(({ obj }) => {
        return get(obj, 'properties.rate', '');
    })
    rate: number;

    @Expose()
    @Transform(({ obj }) => {
        return get(obj, 'properties.rateNumber', '');
    })
    rateNumber: number;

    @Expose()
    @Transform(({ obj }) => {
        const audios = get(obj, 'audios', []);
        let audioFiles = JSON.parse(JSON.stringify(plainToInstance(AudioModel, audios, {})));

        if (audioFiles && audioFiles.length) {
            audioFiles.sort((a, b) => (a.position < b.position ? -1 : b.id > a.id ? -1 : 0));
        }

        return audioFiles;
    })
    audios: AudioModel[];

    @Expose()
    @Transform(({ obj }) => {
        return plainToInstance(AudioFileModel, get(obj, 'properties.ebook', {}), {});
    })
    ebook: AudioFileModel;
}

@Exclude()
export class AudioModel {
    @Expose()
    id: string;

    @Expose()
    title: string;

    @Expose()
    description: string;

    @Expose()
    position: number;

    @Expose()
    minutes: number;

    @Expose()
    url: string;

    @Expose()
    url2: string;

    @Expose()
    @Type(() => AudioFileModel)
    source: AudioFileModel;
}

@Exclude()
export class AudioFileModel {
    @Expose()
    id: string;

    @Expose()
    name: string;

    @Expose()
    alt: string;

    @Expose()
    @Transform(({ obj }) => {
        return UPLOAD_SERVER + get(obj, 'url', '');
    })
    url: string;

    @Expose()
    size: number;

    @Expose()
    mime: string;

    @Expose()
    ext: string;
}

@Exclude()
export class BookReviewModel {
    @Expose()
    id: string;

    @Expose()
    content: string;

    @Expose()
    @Transform(({ obj }) => {
        const photos = get(obj, 'photos', {});

        return plainToInstance(ImageModel, photos, {});
    })
    photos: ImageModel[];

    @Expose()
    @Transform(({ obj }) => {
        const account = get(obj, 'account', {});

        return plainToInstance(AccountModel, account, {});
    })
    account: AccountModel;

    @Expose()
    @Transform(({ obj }) => {
        const book = get(obj, 'book', {});

        return plainToInstance(BookModel, book, {});
    })
    book: BookModel;

    @Expose()
    rate: number;

    @Expose()
    createdAt: string;
}
