import { Exclude, Expose } from 'class-transformer';

// Constants
import { API_BACKEND_URL } from '@Src/constant';

export const CATEGORY_SERVICE_DEFINED = {
    baseUrl: API_BACKEND_URL,
    categories: () => 'categories',
};

@Exclude()
export class CategoryModel {
    @Expose()
    id: string;

    @Expose()
    name: string;

    @Expose()
    slug: string;

    @Expose()
    star: boolean;
}
